<template>
    <!-- Page-Title -->
    <div class="row">
        <div class="col-sm-12">
            <div class="page-title-box">
                <h2 class="page-title">Ibu Hamil Risti</h2>
            </div>
        </div>
    </div>
    <!-- end page title end breadcrumb -->
    <div class="row">
        <div class="col-sm-12">
            <div class="row">
                <div class="col-lg-3 col-sm-12">
                    <div class="row">
                        <div class="col-xl-12 col-sm-12">
                            <div class="card overflow-hidden">
                                <div class="card-heading bg-light">
                                    <input type="checkbox" id="filWilayah" v-model="isActive.wilayah"
                                        data-switch="default" @change="changeActive('wilayah', $event)"
                                        :checked="isActive.wilayah" />
                                    <label for="filWilayah" data-on-label="" data-off-label=""></label>
                                    <h3 class="card-title text-uppercase float-right">Filter Wilayah</h3>
                                    <p class="card-sub-title font-13 text-muted">Filter Ibu Hamil Per Wilayah
                                    </p>
                                </div>
                                <div class="card-body">
                                    <p class="m-t-0 header-title"><b>Kecamatan</b></p>
                                    <Select2 :disabled="!isActive.wilayah" v-model="filter.kecKode"
                                        :options="data.kecamatan" @select="selectKecamatan($event)" />
                                    <p class="header-title mt-2"><b>Desa</b></p>
                                    <Select2 :disabled="!isActive.wilayah" v-model="filter.kodeKab" :options="data.desa"
                                        @select="selectDesa($event)" />
                                    <p class="header-title mt-2"><b>Posyandu</b></p>
                                    <Select2 :disabled="!isActive.wilayah" v-model="filter.kdPosyandu"
                                        :options="data.posyandu" @select="selectPosyandu($event)" />
                                </div>
                            </div>
                        </div>
                        <!-- end col -->
                    </div>
                    <div class="row">
                        <div class="col-xl-12 col-sm-12">
                            <div class="card overflow-hidden">
                                <div class="card-heading bg-light">
                                    <input type="checkbox" v-model="isActive.puskesmas" id="filPkm"
                                        data-switch="default" @change="changeActive('puskesmas', $event)"
                                        :checked="isActive.puskesmas" />
                                    <label for="filPkm" data-on-label="" data-off-label=""></label>
                                    <h3 class="card-title text-uppercase float-right">Filter Puskesmas</h3>
                                    <p class="card-sub-title font-13 text-muted">Filter Ibu Hamil Per Puskesmas
                                    </p>
                                </div>
                                <div class="card-body">
                                    <p class="header-title mt-2"><b>Puskesmas : {{ filter.pkmKode }}</b></p>
                                    <Select2 :disabled="!isActive.puskesmas" v-model="filter.pkmKode"
                                        :options="data.puskesmas" />
                                </div>
                            </div>
                        </div>
                        <!-- end col -->
                    </div>
                    <!--end row -->
                    <div class="row mb-2">
                        <div class="col-xl-12 col-sm-12">
                            <div class="form-group ml-1">
                                <div class="checkbox checkbox-custom">
                                    <input v-model="tampilkan_resiko" id="tampilkan_resiko" type="checkbox">
                                    <label for="tampilkan_resiko">
                                        Tampilkan Nama Faktor Resiko
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-xl-12 col-sm-12">
                            <button @click="showData()"
                                class="btn btn-custom btn-block waves-effect waves-light w-lg m-b-5"
                                :disabled="is_loading"><span
                                    :class="{ 'spinner-grow spinner-grow-sm mr-2': is_loading }" role="status"
                                    aria-hidden="true"></span>{{ is_loading ? "Loading..." : "Tampilkan Data"
                                    }}</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12 col-sm-12">
                            <button @click="exportExcel()"
                                class="btn btn-custom btn-block waves-effect waves-light w-lg m-b-5"
                                :disabled="is_loading"><span
                                    :class="{ 'spinner-grow spinner-grow-sm mr-2': is_loading }" role="status"
                                    aria-hidden="true"></span>{{ is_loading ? "Loading..." : "Export Excel"
                                    }}</button>
                        </div>
                    </div>
                </div>

                <div class="col-lg-9 col-sm-12">
                    <div class="card overflow-hidden bg-light">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-2">
                                    <p class="m-t-0 header-title"><b>Jenis Risti</b></p>
                                    <Select2 v-model="filter.rinci_risti" :options="data.rinci_risti" />
                                </div>
                                <div class="col-md-2">
                                    <p class="m-t-0 header-title"><b>Jenis Bumil</b></p>
                                    <Select2 id="filJenis" v-model="filter.filJenis" :options="data.jenis" />
                                </div>
                                <div class="col-md-2">
                                    <p class="m-t-0 header-title"><b>Gakin</b></p>
                                    <Select2 id="filGakin" :placeholder="filter.filGakin" v-model="filter.filGakin"
                                        :options="data.gakin" />
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-3" style="margin-top:30px;">
                                            <div class="checkbox checkbox-custom">
                                                <input v-model="isActive.hpl" @change="changeHpl($event)" id="aktif_hpl"
                                                    type="checkbox">
                                                <label for="aktif_hpl">
                                                    Periode HPL
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4 mt-4">
                                            <Select2 v-model="filter.hpl_bulan" :disabled="!isActive.hpl"
                                                :options="data.hpl_bulan" />
                                        </div>
                                        <div class="col-md-4 mt-4">
                                            <Select2 v-model="filter.hpl_tahun" :disabled="!isActive.hpl"
                                                :options="data.hpl_tahun" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card overflow-hidden">
                        <div class="card-heading bg-light">
                            <h3 class="card-title text-uppercase">Data Ibu Hamil Risti</h3>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table id="tb-risti" class="table table-striped table-bordered nowrap"
                                    style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                                    <thead>
                                        <tr>
                                            <th>No Register</th>
                                            <th>Nama</th>
                                            <th>Umur</th>
                                            <th>Suami</th>
                                            <th>Jenis</th>
                                            <th>Gakin</th>
                                            <th>Posyandu</th>
                                            <th>RT</th>
                                            <th>RW</th>
                                            <th>Dusun</th>
                                            <th>Desa</th>
                                            <th>HTP</th>
                                            <th>{{ tampilkan_resiko ? 'Umur < 20, Umur > 35' : 'F1' }}</th>
                                            <th>{{ tampilkan_resiko ? 'Paritas > 4' : 'F2' }}</th>
                                            <th>{{ tampilkan_resiko ? 'Jarak Kelahiran < 2 Thn' : 'F3' }}</th>
                                            <th>{{ tampilkan_resiko ? 'BB < 45 Kg' : 'F4' }}</th>
                                            <th>{{ tampilkan_resiko ? 'KEK(Lila < 23,5 cm)' : 'F5' }}</th>
                                            <th>{{ tampilkan_resiko ? 'TB < 145 cm' : 'F6' }}</th>
                                            <th>{{ tampilkan_resiko ? 'Pendarahan' : 'R1' }}</th>
                                            <th>{{ tampilkan_resiko ? 'Infeksi' : 'R2' }}</th>
                                            <th>{{ tampilkan_resiko ? 'Tensi, pre eklamsi, eklamsi' : 'R3' }}</th>
                                            <th>{{ tampilkan_resiko ? 'HB < 11 gr%' : 'R4' }}</th>
                                            <th>{{ tampilkan_resiko ? 'Hiperemesis gravidarum' : 'R5' }}</th>
                                            <th>{{ tampilkan_resiko ? 'Kelainan Letak Janin' : 'R6' }}</th>
                                            <th>{{ tampilkan_resiko ? 'Riwayat Obstetri Jelek' : 'R7' }}</th>
                                            <th>{{ tampilkan_resiko ? 'Penyakit Kronis' : 'R8' }}</th>
                                            <th>{{ tampilkan_resiko ? 'Hamil Kembar 2 atau lebih' : 'R9' }}</th>
                                            <th>{{ tampilkan_resiko ? 'Hamil Kembar Air' : 'R10' }}</th>
                                            <th>{{ tampilkan_resiko ? 'Protein Uria' : 'R11' }}</th>
                                            <th>{{ tampilkan_resiko ? 'Riwayat Operasi Cesar' : 'R12' }}</th>
                                            <th>Jenis Risti</th>
                                            <th>Rincian Risti</th>
                                            <th>G</th>
                                            <th>P</th>
                                            <th>A</th>
                                            <th>Lila</th>
                                            <th>S.Akhir</th>
                                            <th>D.Akhir</th>
                                            <th>HB.Akhir</th>
                                        </tr>
                                    </thead>
                                    <tbody></tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import Select2 from 'vue3-select2-component';
import { useToast } from "vue-toastification";

export default {
    name: 'Home',
    components: {
        Select2
    },
    setup() {
        const toast = useToast();
        return {
            toast
        }
    },
    data() {
        return {
            risti: [],
            table: null,
            is_loading: false,
            isActive: {
                puskesmas: false,
                wilayah: true,
                periode: false,
                hpl: false
            },
            header:[],
            tampilkan_resiko:false,
            filter: {
                pkmKode: '',
                kecKode: '',
                kodeKab: 'all',
                kdPosyandu: 'all',
                dateStart: '',
                dateEnd: '',
                hpl_bulan: new Date().getMonth() + 1,
                hpl_tahun: '',
                rinci_risti: 'all',
                filJenis: 'all',
                filGakin: '000',
            },
            data: {
                puskesmas: [],
                kecamatan: [],
                desa: [],
                posyandu: [{ id: 'all', text: '- Semua Posyandu -' }],
                jenis: [{ id: 'all', text: '- Semua Jenis -' }],
                gakin: [
                    { id: '000', text: 'Semua Bumil' },
                    { id: '0', text: 'Non Gakin' },
                    { id: '1', text: 'Gakin' }
                ],
                hpl_bulan: [
                    { id: '1', text: 'Januari' },
                    { id: '2', text: 'Februari' },
                    { id: '3', text: 'Maret' },
                    { id: '4', text: 'April' },
                    { id: '5', text: 'Mei' },
                    { id: '6', text: 'Juni' },
                    { id: '7', text: 'Juli' },
                    { id: '8', text: 'Agustus' },
                    { id: '9', text: 'September' },
                    { id: '10', text: 'Oktober' },
                    { id: '11', text: 'November' },
                    { id: '12', text: 'Desember' }
                ],
                rinci_risti: [
                    { id: 'all', text: 'Semua Faktor Resiko/Resiko Tinggi' },
                    { id: 'Faktor1', text: 'F1 - Faktor Umur' },
                    { id: 'Faktor2', text: 'F2 - Faktor Paritas' },
                    { id: 'Faktor3', text: 'F3 - Faktor Jarak Kehamilan' },
                    { id: 'Faktor4', text: 'F4 - Faktor BB' },
                    { id: 'Faktor5', text: 'F5 - Faktor Lila' },
                    { id: 'Faktor6', text: 'F6 - Faktor TB' },
                    { id: 'Resiko1', text: 'R1 - Risti Pendarahan' },
                    { id: 'Resiko2', text: 'R2 - Risti Infeksi' },
                    { id: 'Resiko3', text: 'R3 - Risti Pre Eklampsi' },
                    { id: 'Resiko4', text: 'R4 - Risti HB' },
                    { id: 'Resiko5', text: 'R5 - Risti Hiperemesi Gravidarum' },
                    { id: 'Resiko6', text: 'R6 - Risti Letak Kalainan Janin' },
                    { id: 'Resiko7', text: 'R7 - Risti Riwayat Obstetri Jelek' },
                    { id: 'Resiko8', text: 'R8 - Risti Riwayat Penyakit Kronis' },
                    { id: 'Resiko9', text: 'R9 - Risti Kembar 2/Lebih' },
                    { id: 'Resiko10', text: 'R10 - Risti Kembar Air' },
                    { id: 'Resiko11', text: 'R11 - Risti Protein Urea' },
                    { id: 'Resiko12', text: 'R12 - Risti Lain - lain' },
                ],
                hpl_tahun: []
            }
        };
    },
    methods: {
        async getPuskesmas() {
            await axios.get(`dasar/puskesmas`)
                .then(response => {
                    this.data.puskesmas = response.data.content.puskesmas.map(pkm => (
                        { id: pkm.Kode, text: pkm.Puskesmas.toUpperCase() }
                    ));
                    this.filter.pkmKode = this.data.puskesmas[0].id;
                })
                .catch(e => {
                    this.$toast.error(e.response.data.errors.message.description);
                })
        },
        async getKecamatan() {
            await axios.get(`dasar/kecamatan`)
                .then(response => {
                    this.data.kecamatan = response.data.content.kecamatan.map(kec => (
                        { id: kec.Kode_Kecamatan, text: kec.Kecamatan.toUpperCase() }
                    ));
                    this.filter.kecKode = this.data.kecamatan[0].id;
                    this.getDesa();
                })
                .catch(e => {
                    this.toast.error(e.response.data.errors.message.description);
                });
        },
        async getDesa() {
            await axios.get(`dasar/desa/` + this.filter.kecKode)
                .then(response => {
                    var desa = response.data.content.desa.map(item => (
                        { id: item.KodeKab, text: item.Desa.toUpperCase() }
                    ));

                    var defDesa = [{ id: 'all', text: '- Semua -' }];
                    this.data.desa = [...defDesa, ...desa];
                    this.filter.kodeKab = this.data.desa[0].id;
                })
                .catch(e => {
                    this.toast.error(e.response.data.errors.message.description);
                })
        },
        async getPosyandu() {
            await axios.get(`dasar/posyandu/` + this.filter.kodeKab)
                .then(response => {
                    var posyandu = response.data.content.posyandu.map(item => (
                        { id: item.Kode_Posyandu, text: item.Posyandu.toUpperCase() }
                    ));

                    var defPosyandu = [{ id: 'all', text: '- Semua Posyandu -' }];
                    this.data.posyandu = [...defPosyandu, ...posyandu];
                    this.filter.kdPosyandu = this.data.posyandu[0].id;
                })
                .catch(e => {
                    this.toast.error(e.response.data.errors.message.description);
                })
        },
        async getJenisGakin() {
            await axios.get(`bumil/jenisGakin`)
                .then(response => {
                    var jenis = response.data.content.gakin.map(item => (
                        { id: item.kode, text: item.jenis.toUpperCase() }
                    ));

                    var defJenis = [{ id: 'all', text: '- Semua Jenis -' }];
                    this.data.jenis = [...defJenis, ...jenis];
                    this.filter.filJenis = this.data.jenis[0].id;
                })
                .catch(e => {
                    this.toast.error(e.response.data.errors.message.description);
                })
        },
        selectKecamatan(event) {
            if (event.id !== '') {
                this.getDesa();
            }
        },
        selectDesa(event) {
            if (event.id !== '') {
                this.getPosyandu();
            }
        },
        async showData() {
            this.is_loading = true;
            let current = new Date();
            let cDate   = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
            await axios('bumil/risti', { params:{
                    'kdPkm': (this.isActive.puskesmas) ? this.filter.pkmKode : '',
                    'kdKecamatan': (this.isActive.wilayah) ? this.filter.kecKode : '',
                    'kdDesa': (this.isActive.wilayah && this.filter.kodeKab !== 'all') ? this.filter.kodeKab : '',
                    'kdPosyandu': (this.isActive.wilayah && this.filter.kdPosyandu !== 'all') ? this.filter.kdPosyandu : '',
                    'jnGakin': (this.filter.filJenis !== 'all') ? this.filter.filJenis : '',
                    'gakin': (this.filter.filGakin !== '000') ? this.filter.filGakin : '',
                    'rinci_risti': this.filter.rinci_risti,
                    'periodeRegStart': '2000-01-01',
                    'periodeRegEnd': cDate
                }
            })
            .then(response => {
                this.is_loading = false;
                this.risti = response.data.content;
            })
            .catch(e => {
                this.toast.error(e.response.data.errors.message.description);
            });
        },
        changeActive(current, event) {
            if (current == 'wilayah') {
                this.isActive.puskesmas = false;
                this.isActive.wilayah = true;
            } else {
                this.isActive.wilayah = false;
                this.isActive.puskesmas = true;
            }
        },
        getTahun() {
            var currentYear = new Date().getFullYear();
            var years = [];
            var startYear = 2010;
            while (startYear <= currentYear) {
                var item = startYear++;
                years.push({ id: item, text: item });
            }
            this.data.hpl_tahun = years;
            this.filter.hpl_tahun = currentYear;
        },
        exportExcel() {
            let current = new Date();
            let cDate   = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
            const params = new URLSearchParams({
                'kdPkm': (this.isActive.puskesmas) ? this.filter.pkmKode : '',
                'kdKecamatan': (this.isActive.wilayah) ? this.filter.kecKode : '',
                'kdDesa': (this.isActive.wilayah && this.filter.kodeKab !== 'all') ? this.filter.kodeKab : '',
                'kdPosyandu': (this.isActive.wilayah && this.filter.kdPosyandu !== 'all') ? this.filter.kdPosyandu : '',
                'jnGakin': (this.filter.filJenis !== 'all') ? this.filter.filJenis : '',
                'gakin': (this.filter.filGakin !== '000') ? this.filter.filGakin : '',
                'rinci_risti': this.filter.rinci_risti,
                'periodeRegStart': '2000-01-01',
                'periodeRegEnd': cDate
            });
            window.open(`${process.env.VUE_APP_APIURI}bumil/risti/export?${params.toString()}`, '_blank');
            this.is_loading = false;
        },
        initTable() {
            $('#tb-risti').DataTable().destroy();
            $('#tb-risti').DataTable({
                processing: true,
                destroy: true,
                scrollX: true,
                orders: [],
                data: this.risti,
                columns: [
                    { data: "No_Index_Bumil_Dinas" },
                    { data: "Nama" },
                    { data: "Umur" },
                    { data: "Suami" },
                    { data: "jenisgakinnya" },
                    { data: "Gakin", render:function(data) {return (data == 1) ? 'Gakin' : 'Non Gakin'} },
                    { data: "Posyandu" },
                    { data: "RT" },
                    { data: "RW" },
                    { data: "Dusun" },
                    { data: "Desa" },
                    { data: "HTP" },
                    { data: "Faktor1", render:function(data) {return (data == 1) ? 'Y' : ''} },
                    { data: "Faktor2", render:function(data) {return (data == 1) ? 'Y' : ''} },
                    { data: "Faktor3", render:function(data) {return (data == 1) ? 'Y' : ''} },
                    { data: "Faktor4", render:function(data) {return (data == 1) ? 'Y' : ''} },
                    { data: "Faktor5", render:function(data) {return (data == 1) ? 'Y' : ''} },
                    { data: "Faktor6", render:function(data) {return (data == 1) ? 'Y' : ''} },
                    { data: "Resiko1", render:function(data) {return (data == 1) ? 'Y' : ''} },
                    { data: "Resiko2", render:function(data) {return (data == 1) ? 'Y' : ''} },
                    { data: "Resiko3", render:function(data) {return (data == 1) ? 'Y' : ''} },
                    { data: "Resiko4", render:function(data) {return (data == 1) ? 'Y' : ''} },
                    { data: "Resiko5", render:function(data) {return (data == 1) ? 'Y' : ''} },
                    { data: "Resiko6", render:function(data) {return (data == 1) ? 'Y' : ''} },
                    { data: "Resiko7", render:function(data) {return (data == 1) ? 'Y' : ''} },
                    { data: "Resiko8", render:function(data) {return (data == 1) ? 'Y' : ''} },
                    { data: "Resiko9", render:function(data) {return (data == 1) ? 'Y' : ''} },
                    { data: "Resiko10", render:function(data) {return (data == 1) ? 'Y' : ''} },
                    { data: "Resiko11", render:function(data) {return (data == 1) ? 'Y' : ''} },
                    { data: "Resiko12", render:function(data) {return (data == 1) ? 'Y' : ''} },
                    { data: "TingkatRistinya" },
                    { data: "rinciRisti" },
                    { data: "G" },
                    { data: "P" },
                    { data: "A" },
                    { data: "Lila" },
                    { data: "Sistoleakhir" },
                    { data: "DiastoleAkhir" },
                    { data: "HBAkhir" },
                ],
                columnDefs:[
                    {
                        targets:[12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29],
                        className:"text-center"
                    }
                ],
                language: {
                    "lengthMenu": "Menampilkan _MENU_ data per halaman",
                    "zeroRecords": "Belum ada data untuk saat ini",
                    "info": "Menampilkan halaman _PAGE_ dari _PAGES_",
                    "infoEmpty": "Belum ada data",
                    "infoFiltered": "(Filter dari _MAX_ total data)",
                    "paginate": {
                        "next": "Selanjutnya",
                        "previous": "Sebelumnya"
                    },
                    "search": "Cari data : "
                },
            });
        }
    },
    watch: {
        risti() {
            this.initTable();
        },
        tampilkan_resiko() {
            this.showData();
        },
        $route(to, from) {
            $('#tb-risti').DataTable().clear().draw();
            $('#tb-risti').DataTable().destroy();
        }
    },
    async created() {
        await this.getPuskesmas();
        await this.getKecamatan();
        await this.getTahun();
        await this.getJenisGakin();
        await this.$nextTick(function () {
            $('#tb-risti').DataTable();
        })
    }
}
</script>